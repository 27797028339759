import React from "react";

function AboutSection() {
  return (
    <>
      <div className="header-about">
        <div className="sectionwrapper">
          <h1 className="title">My Works</h1>
        </div>
      </div>
    </>
  );
}

export default AboutSection;
